<template>
    <div id="courseIntroductionDetails">
        <div class="box">
            <div class="breadCrumbs">
               
            </div>
            <div class="titleSystemClass" style="margin-bottom:0.1%;">
                    <img  @click="onBack" src="@/assets/img/common/close01.png" style="width: 20px;height: 20px;position: relative;cursor: pointer;line-height: 60px;right: 20px; font-weight: bold; font-size: 16px;float: right;top:20px" />
                
                试卷预览
            </div>
            <div style="width: 100%;height: 34px;background: #fff;font-size: 14px;display: flex;align-items: center;">
                <div style="margin: 0 1.5%;">
                    <span style="color:#666;">类型：</span>
                    <span style="color:#333;">课后作业</span>
                </div>
                <div style="margin: 0 1%;">
                    <span style="color:#666;">章名称：</span>
                    <span style="color:#333;">{{ zhangItem.catalogueName }}</span>
                </div>
                <div style="margin: 0 1%;">
                    <span style="color:#666;">节任务：</span>
                    <span style="color:#333;">{{jieName }}</span>
                </div>
                <div style="margin: 0 1%;">
                    <span style="color:#666;">作业名称：</span>
                    <span style="color:#333;">{{ exercisesnName }}</span>
                </div>
                <div style="margin: 0 1%;font-size: 12px;color: #F50000;">
                    <p>注：当前仅能更换同类型试题，如需添加题型或改变题型数量，请先更改出题规则</p>
                </div>
            </div>
            <div class="system">
               
                <div style="width: 100%;height: 700px;overflow-y: auto;">
                    <p style="font-weight: 600;">一、填空题</p>
                    <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(item,index) in tiankongList" :key="index+'index'">
                      <div v-if="item.topicType == 3">
                          <div style="margin-top: 10px;font-size: 13px;">
                              <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ index+1 }}</span> {{ item.testQuestionContent }}  
                                  <el-button
                                      style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                      size="mini"
                                      @click="tiankongQuestions(item,index)"
                                      >替换试题</el-button>
                                  </p>
                          </div>
                          
                      </div>
                    </div>
                   
                   
                   
                    <p style="font-weight: 600;padding-top: 10px;">二、单选题</p>
                    <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(danxuan,indexdx) in danxuanList" :key="indexdx+'indexdx'">
                      <div v-if="danxuan.topicType == 1">
                          <div style="margin-top: 10px;font-size: 13px;">
                              <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexdx+1 }}</span> {{ danxuan.testQuestionContent }}  
                                  <el-button
                                      style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                      size="mini"
                                      @click="duanxuanQuestions(danxuan,indexdx)"
                                      >替换试题</el-button>
                                  </p>
                              <el-radio-group>
                                  <el-radio  v-for="(item1,index1) in danxuan.answers" :key="index1" :label="item1.isAnswer">{{item1.isOrder == 1?'A':item1.isOrder == 2?'B':item1.isOrder == 3?'C':item1.isOrder == 4?'D':''}} {{ item1.content }}</el-radio>
                              </el-radio-group>
                          </div>
                          
                      </div>
                    </div>
                   

                    <p style="font-weight: 600;padding-top: 10px;">三、多选题</p>
                    <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(duoxuan,indexduoxuan) in duoxuanList" :key="indexduoxuan+'indexduoxuan'">
                      <div v-if="duoxuan.topicType == 2">
                          <div style="margin-top: 10px;font-size: 13px;">
                              <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexduoxuan+1 }}</span> {{ duoxuan.testQuestionContent }}  
                                  <el-button
                                      style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                      size="mini"
                                      @click="duoxuanQuestions(duoxuan,indexduoxuan)"
                                      >替换试题</el-button>
                                  </p>
                                  <el-radio-group>
                                      <el-radio  v-for="(item3,index4) in duoxuan.answers" :key="index4+'index4'" :label="item3.isAnswer">{{item3.isOrder == 1?'A':item3.isOrder == 2?'B':item3.isOrder == 3?'C':item3.isOrder == 4?'D':''}} {{ item3.content }}</el-radio>
                                  </el-radio-group>
                              
                          </div>
                          
                      </div>
                    </div>


                    <p style="font-weight: 600;padding-top: 10px;">四、判断题</p>
                    <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(itempanduan,indexpanduan) in panduanList" :key="indexpanduan+'indexindexpanduan'">
                      <div v-if="itempanduan.topicType == 4">
                          <div style="margin-top: 10px;font-size: 13px;">
                              <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexpanduan+1 }}</span> {{ itempanduan.testQuestionContent }}  
                                  <el-button
                                      style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                      size="mini"
                                      @click="panduanQuestions(itempanduan,indexpanduan)"
                                      >替换试题</el-button>
                                  </p>
                              <el-radio-group>
                                  <el-radio  v-for="(item5,index2) in itempanduan.answers" :key="index2" :label="item5.isAnswer">{{item5.isOrder == 1?'A':item5.isOrder == 2?'B':item5.isOrder == 3?'C':item5.isOrder == 4?'D':''}} {{ item5.content }}</el-radio>
                              </el-radio-group>
                          </div>
                          
                      </div>
                    </div>
                </div>
     
                <div style="text-align: center;" class="dialog-footer">
                  
                    <el-button type="primary" style="background-color: #76C8F6;border: none;margin-bottom: 20px;" round @click="confirmClick">
                    确认试题
                    </el-button>
                </div>
             
                
            </div>
       
        </div>
        <el-dialog
        title="替换试题"
        v-model="dialogVisible" width="50%"  :modal="false"
        :before-close="handleClose">
         <div class="searchList">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="章名称:" >
                <el-select
                @change="zhangChange"
                  v-model="zhangValue"
                  placeholder="请选择章名称"
                  clearable
                >
                <el-option
              v-for="item in zhangOptions"
              :key="item.id"
              :label="item.catalogueName"
              :value="item.id"
            />
                </el-select>
              </el-form-item>
              <el-form-item label="节名称:">
                <el-select
                  v-model="jieValue"
                  placeholder="请选择节名称"
                  clearable
                >
                <el-option
              v-for="item in jieOptions"
              :key="item.id"
              :label="item.catalogueName"
              :value="item.id"
            />
                </el-select>
              </el-form-item>

              <el-form-item label="难度:">
                <el-select
                  v-model="nanduValue"
                  placeholder="请选择难度"
                  clearable
                >
                <el-option
              v-for="item in nanduOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
                </el-select>
              </el-form-item>
              <el-form-item label="上传人:">
                <el-input v-model="upDataName" placeholder="请输入上传人" clearable />
              </el-form-item>
              <el-form-item label="题干:">
                <el-input v-model="stemData" placeholder="请输入题干" clearable />
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary"  @click="onSubmit">搜索</el-button>
              </el-form-item>
             
            
            </el-form>
            <div class="searchButton">
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;float:right;margin-top:30px;margin-right:20px;">添加题库</el-button>
                <el-button type="primary" round style="background-color: #76C8F6;border: none;float:right;margin-top:30px;margin-right:20px;">添加题库</el-button> -->

            </div>
          </div>
          <el-table :data="tableData" stripe style="width: 100%;height:500px" max-height="394px">
            <el-table-column fixed type="index"  label="序号" />

            <el-table-column prop="catalogueName" label="章名称"  />
            <el-table-column prop="sectionName" label="节名称" />
    
            <el-table-column prop="chapters" label="难度"  width="110" >
              <template #default="scope">
            <span type="text">{{ scope.row.topicDifficulty == 1?'简单':scope.row.topicDifficulty == 2?'中等':'困难' }}</span>
          </template>
            </el-table-column>
            <el-table-column prop="testQuestionContent" label="题干" width="150" />
            <el-table-column prop="correctAnswer" label="正确答案" width="150" />
            <el-table-column prop="testQuestionAnalysis" label="解析" width="150" />
            <el-table-column prop="insertTime" label="上传时间" width="200" />
            <el-table-column prop="realName" label="上传人" />
            <el-table-column  label="操作" fixed="right" width="160" >
              <template #default="scope">
                  <el-button type="text" @click="deselect(scope.row)" v-if="testQuestionId != scope.row.oldId && oldId != scope.row.oldId">{{scope.row.select == 1?'已选择':'选择'}}</el-button>
                  <span v-else>已选择</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
          <el-pagination v-if="total>5" style="margin: 0 auto;text-align: center;" :page-size="pageSize" :current-page="currentPage" layout="prev, pager, next" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        <div style="text-align: center;margin-top: 20px;">
            <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="cancellation">确定</el-button>
        </div>
        
        </el-dialog>
       


    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,} from "vue";
import { useRouter,useRoute,onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util"
import {get_select_courseList,get_select_courseChapterList,get_select_courseSectionList,get_select_questionList} from '@/js/teachingSquare'
import { get_topicCount,f_intsert_generateCoursePreviewPapers,f_get_systemExperienceExercises,f_update_replaceQuestions} from '@/js/classManagement'
export default {
    setup () {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const state = reactive({
            examinationPaperId:route.query.examinationPaperId,
            oldId:'',
            questionsIsoder:'',
              total:0,
            currentPage: 1,
            pageSize: 4,
            radio:true,
            zhangValue:'',
            zhangOptions:[],
            jieValue:'',
            jieOptions:[],
            nanduValue:1,
            nanduOptions:[
            {
              value: 1,
              label: "简单",
            },
            {
              value: 2,
              label: "中等",
            },
            {
              value: 3,
              label: "困难",
            },
            ],
            upDataName:'',
            stemData:'',
            checkList:[],
            subjectOptions:[],
            courseName:'',
            jieName:route.query.jieName,
            exercisesnName:route.query.exercisesnName,
            watchTitle:route.query.watchTitle,
            zhangItem:getStorage('zhangItem'),
            radio2:'',
            RemainingTimeshi: 0,
            RemainingTimefen: 58,
            RemainingTimemiao: 56,
            dialogVisible:false,
            topicType:'',
            testQuestionId:'',
            oldId:'',
            questionsIndex:null,
            wrongVisible:false,
               formInline:{

        },
        questionsRow:{},
        tableData:[
       
      ],
      questionsList:getStorage("questionsList"),
      danxuanList:[],
      duoxuanList:[],
      tiankongList:[],
      panduanList:[],
   
        });
        onMounted(() => {
            console.log(state.jieName);
        console.log(getStorage("questionsList"));
        getStorage("questionsList").forEach(element => {
                if (element.topicType == 1) {
                state.danxuanList.push(element);
                console.log(state.danxuanList);
            }else if(element.topicType == 2){
                state.duoxuanList.push(element);
            }else if(element.topicType == 3){
                state.tiankongList.push(element)
            }else{
                state.panduanList.push(element)
                console.log(state.panduanList);
            }
            
            
        });
        state.courseName = getStorage('classDetailData').courseId
       
      });
      onBeforeRouteLeave((to, from,next) =>{
        console.log(to);
      // 设置下一个路由的 meta
      if (to.name == "createExercises") {
        to.meta.isBack = true;
        console.log(to ,from);
        next();
      }
      
    
    });
    const methods = {
              // 确认
              confirmClick(){
            state.questionsList = [];
            console.log(state.danxuanList,state.questionsList);
            state.questionsList = state.questionsList.concat(state.danxuanList,state.duoxuanList,state.tiankongList,state.panduanList);
            setStorage('questionsList',state.questionsList)
            router.go(-1);
            // console.log(state.questionsList);
        },
              // 获取试题列表
              get_select_questionList(){
            let params = {
                courseId:state.courseName,
                courseChapterId:state.zhangValue,
                courseSectionId:state.jieValue,
                questionBankType:1,
                topicDifficulty:state.nanduValue,
                pageIndex:state.currentPage,
                pageSize:state.pageSize,
                content:state.stemData,
                topicType:state.topicType,
                userName:state.upDataName,
            }
            get_select_questionList(params).then(res=>{
                if (res.data.code == 1) {
                    state.tableData = res.data.data.list;
                    state.total = res.data.data.totalCount.Value;
                    if (state.tableData.length>0) {
                        state.tableData.forEach(element => {
                        element.answers.forEach(item => {
                            if (item.isAnswer) {
                                element.correctAnswer = item.content;
                            }
                        });
                    });
                    }
                    
                }
            })
        },
                // 操作选择
                deselect(row){
                    state.tableData.forEach(element => {
                    element.select = 0;
                });
                row.select = 1;
                state.questionsRow = row;
            },
            indexClick(){
                router.push('/home/studentCenter');
            },
            smartConstructionSite(){
                router.push('/home/intelligentConstructionDetail')
            },
         
            handleClose(){
                state.dialogVisible = false;
            },
            // 单选替换试题
        duanxuanQuestions(item,index){
            console.log(item,index);
            state.dialogVisible = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.questionsIndex = index;
            methods.get_select_courseChapterList(state.courseName);
        },
        // 多选替换试题
        duoxuanQuestions(item,index){
            state.dialogVisible = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.questionsIndex = index;
            methods.get_select_courseChapterList(state.courseName);
        },
        // 填空替换试题
        tiankongQuestions(item,index){
            state.dialogVisible = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.questionsIndex = index;
            methods.get_select_courseChapterList(state.courseName);
        },
        // 判断替换试题
        panduanQuestions(item,index){
            state.dialogVisible = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.questionsIndex = index;
            methods.get_select_courseChapterList(state.courseName);
        },
         
          // 获取章下拉列表
          get_select_courseChapterList(courseName) {
                let params = {
                courseId: courseName,
                };
                get_select_courseChapterList(params).then((res) => {
                if (res.data.code == 1) {
                    state.zhangOptions = res.data.data;
                    if (state.zhangOptions.length >0) {
                        state.zhangValue = state.zhangOptions[0].id;
                        methods.get_select_courseSectionList(state.zhangValue);
                    }
                    
                    
                }
                });
            },
             // 章的监听事件
             zhangChange(index) {
                state.jieValue = "";
                    // state.ruleForm.domains2[index].jieId = "";
                    methods.get_select_courseSectionList(state.zhangValue);
                
                },
            get_select_courseSectionList(zhangId) {
                    let params = {
                    courseChapterId: zhangId,
                    };
                    get_select_courseSectionList(params).then((res) => {
                    if (res.data.code == 1) {
                        state.jieOptions = res.data.data;
                        if (state.jieOptions.length>0) {
                            state.jieValue = state.jieOptions[0].id;
                        }
                        
                        methods.get_select_questionList();
                    }
                    });
                },
                onSubmit(){
                  state.currentPage = 1;
                  methods.get_select_questionList();
                },
                        // 分页
        handleSizeChange(val){
            state.pageSize = val;
            methods.get_select_questionList();
        },
        handleCurrentChange(val){
            state.currentPage = val;
            methods.get_select_questionList();
        },
              // 确认
      cancellation() {
        if (state.watchTitle == '编辑') {
            let params = {
                examinationPaperId:state.examinationPaperId,
                oldQuestionsId:state.oldId,
                newQuestionsId:state.questionsRow.oldId,
                isOrder:state.questionsIsoder
            }
            f_update_replaceQuestions(params).then(res=>{
                if (res.data.code == 1) {
                    const item = res.data.data;
                    if (item.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = item.id;
                state.danxuanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.danxuanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = item.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = item.id;
                state.duoxuanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = item.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 3){
                state.tiankongList[state.questionsIndex].id = item.id;
                state.tiankongList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.tiankongList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = item.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 4){
                state.panduanList[state.questionsIndex].id = item.id;
                state.panduanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.panduanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = item.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
                }else{
                    if (state.questionsRow.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.danxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.danxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.duoxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 3){
                state.tiankongList[state.questionsIndex].id = state.questionsRow.newId;
                state.tiankongList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.tiankongList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = state.questionsRow.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 4){
                state.panduanList[state.questionsIndex].id = state.questionsRow.newId;
                state.panduanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.panduanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
                }
                state.dialogVisible = false;
            })
        }else{
            let obj = {};
        if (Object.keys(state.questionsRow).length>0) {
          if (state.questionsRow.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.danxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.danxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.duoxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 3){
                state.tiankongList[state.questionsIndex].id = state.questionsRow.newId;
                state.tiankongList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.tiankongList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = state.questionsRow.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 4){
                state.panduanList[state.questionsIndex].id = state.questionsRow.newId;
                state.panduanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.panduanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
        }else{
            console.log(1111);
        }
        state.dialogVisible = false;
        }
        console.log(state.questionsRow);
        

      },
            // 返回
            onBack(){
               
                    router.go(-1)
                
                
            },
    };
    




        return{
            ...toRefs(state),
            ...methods,
        }
    }
}
</script>
<style  lang="scss" scoped>
#courseIntroductionDetails{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }
    .breadCrumbs{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .rightBox{
        width: 39%;
        height:800px;
        background: #fff;
        float: right;
        overflow-y: auto;
    }
    
   :deep .el-collapse-item__header{
        background: #F7F8FA;
    }
    :deep .el-collapse-item__wrap{
        border: none;
    }
    .titleSystemClass{
        width: 100%;
        height: 60px;
        background: #fff;
        text-align: center;
        line-height: 60px;
        font-size: 18px;
        font-weight: 800;
    }
    .system{
        width: 100%;
        margin-top: 20px;
        .onReplace{
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #76C8F6;
            background: rgba(118,200,246,0.14);
            border-radius: 2px;
            border: 1px solid #76C8F6;
            padding: 7px 8px;
            cursor: pointer
        }
    }
    div::-webkit-scrollbar {
    width: 4px;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    opacity: 0.2;;
  }
  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }
   .searchList{
        padding: 0 20px 10px 24px;
        box-sizing: border-box;
        .el-form-item{
          margin-bottom: 10px;
          :deep .el-button{
              border:none;
              background: #76C8F6;
          }
        }
        :deep .el-input{
          width: 146px;
          margin-right: 21.4px;
          box-sizing: border-box;
        }
      }
}
</style>